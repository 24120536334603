import { GAME_PAGE_URL } from '~/constants/lobby'
import {
  IMenuHeader,
  IMenuUserDesktop,
  IMenuWithDraw,
  IMenuDepositBank,
  IMenuDepositDesktop,
  IMenuDeposit,
  IMenuMobile,
  IMenuBottom
} from '~/types/menu.type'
import { ACCOUNT_URLS, LOBBY_GAME_URLS, PAGE_URLS, SPORTS_URLS } from '~/config/page-url'
import { LABEL } from '~/constants/account/history/transactions'
import { IMenuItemMb } from '~/types/home'
// const { $config } = useNuxtApp()

export const PATH_ICON = '/assets/images/components/desktop/menu/'

export const PATH_ICON_ACCOUNT = '/assets/images/components/desktop/menu/account/'

export const LOGO_SITE_IMAGE = '/assets/brand/logo.svg'

export const IMAGE_PATH_ACCOUNT = '/assets/images/components/desktop/pages/account/layout/'
export const IMAGE_PATH_DEPOSIT = '/assets/images/components/desktop/pages/account/deposit/'
export const TRANSACTION_P2P_TYPE = {
  BUY: 'buy',
  SELL: 'sell'
}
export const PATH_ICON_ACC_LIGHT = '/assets/images/components/mobile/light/account/'

export const MENUS: IMenuHeader[] = [
  {
    type: 'sports',
    name: 'sports',
    tab: 'tab-sport',
    title: 'Thể thao',
    activeUrl: '/sports'
  },
  {
    type: 'livecasino',
    name: 'livecasino',
    title: 'Live Casino',
    tab: 'tab-casino',
    activeUrl: GAME_PAGE_URL.all,
    subMenu: []
  },
  {
    type: 'games',
    name: 'game-type',
    title: 'Cổng Game',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.ALL,
    subMenu: []
  },
  {
    name: 'line',
    title: '',
    tab: '',
    activeUrl: '',
    subMenu: undefined
  },
  {
    name: 'khuyen-mai',
    title: 'Khuyến mãi',
    tab: 'tab-promotion',
    activeUrl: '/khuyen-mai',
    subMenu: []
  }
]

export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: PATH_ICON_ACCOUNT + 'help-register.svg',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-profile',
    imgLight: 'icon-profile-light'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: PATH_ICON_ACCOUNT + 'icon-deposit.svg',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit2',
    imgLight: 'icon-deposit-light'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: PATH_ICON_ACCOUNT + 'icon-withdraw.svg',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw2',
    imgLight: 'icon-withdraw-light'
  },
  {
    id: 4,
    name: 'Mua bán P2P',
    url: ACCOUNT_URLS.P2P,
    icon: PATH_ICON_ACCOUNT + 'icon-p2pc.svg',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-p2pc',
    imgLight: 'icon-p2p-light'
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: PATH_ICON_ACCOUNT + 'icon-history.svg',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history',
    imgLight: 'icon-history-light'
  },
  {
    id: 6,
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.BONUS,
    icon: PATH_ICON_ACCOUNT + 'icon-bonus.svg',
    activeUrl: ACCOUNT_URLS.BONUS,
    activeUrlRelative: ACCOUNT_URLS.BONUS,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-gift',
    imgLight: 'icon-bonus-light'
  },
  {
    id: 7,
    name: 'Ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: PATH_ICON_ACCOUNT + 'icon-bank.svg',
    activeUrl: ACCOUNT_URLS.BANK,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-bank',
    imgLight: 'icon-bank-light'
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: 'logout',
    icon: PATH_ICON_ACCOUNT + 'icon-logout.svg',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank-bold',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Giao dịch P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'sell',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`,
    icon: 'icon-p2pc',
    iconMB: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_WITHDRAW_DESKTOP: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    key: 'bank',
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    key: 'crypto',
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    key: 'cardList',
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    nameMB: 'Codepay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    nameMB: 'Codepay 2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Nạp P2P',
    nameMB: 'Giao dịch P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'buy',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    key: 'buy',
    icon: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    nameMB: 'Tiền ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    icon: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    nameMB: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: ACCOUNT_URLS.DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    nameMB: 'Thẻ cào',
    desc: 'Nhiều nhà mạng hỗ trợ',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân Hàng',
    queryValue: 'codepay',
    fullPath: [ACCOUNT_URLS.DEPOSIT_BANK, ACCOUNT_URLS.DEPOSIT_DA, ACCOUNT_URLS.DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền Ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví Điện Tử',
    queryValue: 'e-wallet',
    fullPath: [ACCOUNT_URLS.DEPOSIT_MOMO, ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY, ACCOUNT_URLS.DEPOSIT_ZALO_PAY],
    key: 'e-wallet',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ Cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]
export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    pathActive: ACCOUNT_URLS.DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: 'icon-codepay',
    isHot: true,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: 'icon-codepay2',
    isHot: false,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(
      () => import('~/components/mobile/pages/account/history/transactions/index.vue')
    )
  },
  {
    name: LABEL.HISTORY_BETS,
    link: ACCOUNT_URLS.BET_HISTORY,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const USER_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-icon-profile',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-icon-bank',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Giao dịch P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-icon-p2p',
    activeUrl: [ACCOUNT_URLS.P2P],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${ACCOUNT_URLS.TRANSACTION_HISTORY}`,
    icon: 'icon-icon-history-transaction',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${ACCOUNT_URLS.BET_HISTORY}`,
    icon: 'icon-icon-history-bets',
    activeUrl: [ACCOUNT_URLS.BET_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Tiền thưởng',
    url: '',
    icon: 'icon-icon-gift',
    activeUrl: [''],
    isLiveChat: false
  },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]

export function menuBottom(isHome: boolean): IMenuItemMb[] {
  return [
    {
      name: isHome ? 'Khuyến Mãi' : 'Trang Chủ',
      icon: `/assets/images/components/common/menu-bottom/icon-${isHome ? 'promotion' : 'home'}.svg`,
      link: isHome ? PAGE_URLS.PROMOTION : PAGE_URLS.HOME,
      loginRequired: false
    },
    {
      name: 'Lịch Sử',
      icon: '/assets/images/components/common/menu-bottom/icon-history.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-history.svg',
      link: `${ACCOUNT_URLS.TAB_HISTORY}`,
      loginRequired: true
    },
    {
      name: 'Nạp Rút',
      icon: '/assets/images/components/common/menu-bottom/icon-deposit-active.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-deposit-active.svg',
      link: ACCOUNT_URLS.TAB_DEOPSIT,
      class: 'deposit',
      loginRequired: true
    },
    {
      name: 'Hỗ Trợ',
      icon: '/assets/images/components/common/menu-bottom/icon-support.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-support.svg',
      link: PAGE_URLS.SUPPORT,
      loginRequired: false
    },
    {
      name: 'Tài Khoản',
      icon: '/assets/images/components/common/menu-bottom/icon-account.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-account.svg',
      link: ACCOUNT_URLS.INDEX,
      class: 'account',
      loginRequired: true
    }
  ]
}

export const LOBBY_SPORTS = [
  {
    title: '',
    imgBackground: '/assets/images/common/menu/goal-sport.webp',
    link: SPORTS_URLS.K_SPORT,
    alias: SPORTS_URLS.K_SPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/saba-sport.webp',
    link: SPORTS_URLS.C_SPORT,
    alias: SPORTS_URLS.C_SPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/bti-sport.webp',
    link: SPORTS_URLS.P_SPORT,
    alias: SPORTS_URLS.C_SPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/the-thao-ao.webp',
    link: SPORTS_URLS.VIRTUAL_SPORTS,
    alias: SPORTS_URLS.VIRTUAL_SPORTS
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/e-sport.webp',
    link: SPORTS_URLS.E_SPORT,
    alias: SPORTS_URLS.E_SPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/lich-thi-dau.webp',
    link: PAGE_URLS.SCHEDULES,
    alias: PAGE_URLS.SCHEDULES
  }
]
